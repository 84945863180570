import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '../../../reusecore/src/elements/Box';
import Text from '../../../reusecore/src/elements/Text';
import Heading from '../../../reusecore/src/elements/Heading';
import Logo from '../../../reusecore/src/elements/UI/Logo';
import Container from '../../../common/src/components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';
import { Link } from "gatsby"
import LogoImage from '../../../common/src/assets/image/saas/logo.png';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      allContentfulBlockSetting {
        edges {
          node {
            footerAddress
            footerMobileNumber
          }
        }
      }

      allContentfulNavItem(filter: {menuPosition: {nin: "Top Menu"}}) {
        group(field: menuPosition) {
          nodes {
            link
            caption
          }
          fieldValue
        }
      }
    }
  `);

 const footerDatatitle = Data.allContentfulBlockSetting.edges[0].node;
  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="#"
              logoSrc={LogoImage}
              title="Hosting"
              logoStyle={logoStyle}
            />
            {footerDatatitle.footerAddress && footerDatatitle.footerAddress != 0 ? (<Text content={footerDatatitle.footerAddress} {...textStyle} />) : ('') }
            {footerDatatitle.footerMobileNumber && footerDatatitle.footerMobileNumber != 0 ? (<Text content={footerDatatitle.footerMobileNumber} {...textStyle} />) : ('') }
          
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.allContentfulNavItem.group.map((widget, index) => (
              <Box className="col" {...col} key={`footer-widget-${index}`}>
                <Heading content={widget.fieldValue} {...titleStyle} />
                <List>
                  {widget.nodes.map((item, index) => (
                    <ListItem key={`footer-list-item-${index}`}>
                      <Link to={item.link} className="ListItem">
                        {item.caption}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: [1, '35%', '35%', '23%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '65%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
    mb: '30px',
  },
  // Default logo size
  logoStyle: {
    width: '160px',
    mb: '15px',
    ml: '-10px',
  },
  // widget text default style
  textStyle: {
    color: '#0f2137',
    fontSize: '16px',
    mb: '10px',
  },
};

export default Footer;
